<template>
  <div>
    <el-card style="min-height: 45rem">
      <div class="flex-def flex-zCenter flex-cCenter flex-zTopBottom">
        <el-form label-position="left" style="width: 26rem">
          <div style="width: 100%;padding: 1rem 0 2rem;font-weight: 600">经销商规则配置</div>
          <el-form-item label="一星升级条件">关系链下含
            <el-input-number v-model="form.up_one_star_need_boos"></el-input-number>个老板
          </el-form-item>
          <el-form-item label="一星分红比例">
            <el-input-number v-model="form.one_star_scale" style="width: 12rem"></el-input-number>%
          </el-form-item>
          <el-form-item label="一星月度考核">业绩需
            <el-input-number v-model="form.one_star_assess"></el-input-number>元
          </el-form-item>
          <el-form-item label="二星升级条件">关系链下含
            <el-input-number v-model="form.up_two_star_need_one_star"></el-input-number>个一星
          </el-form-item>
          <el-form-item label="二星分红比例">
            <el-input-number v-model="form.two_star_scale" style="width: 12rem"></el-input-number>%
          </el-form-item>
          <el-form-item label="二星月度考核">业绩需
            <el-input-number v-model="form.two_star_assess"></el-input-number>元
          </el-form-item>
          <el-form-item label="三星升级条件">关系链下含
            <el-input-number v-model="form.up_three_star_need_two_star"></el-input-number>二星
          </el-form-item>
          <el-form-item label="三星分红比例">
            <el-input-number v-model="form.three_star_scale" style="width: 12rem"></el-input-number>%
          </el-form-item>
          <el-form-item label="三星月度考核">业绩需
            <el-input-number v-model="form.three_star_assess"></el-input-number>元
          </el-form-item>
          <el-form-item align="right">
            <el-button @click="edit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "conf",
  components:{
  },
  data() {
    return {
      form:{
        one_star_scale:0,
        up_one_star_need_boos:0,
        one_star_assess:0,
        two_star_scale:0,
        up_two_star_need_one_star:0,
        two_star_assess:0,
        three_star_scale:0,
        up_three_star_need_two_star:0,
        three_star_assess:0,
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.shop.plugin.dealer.rule().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.shop.plugin.dealer.ruleEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>